import * as Sentry from '@sentry/react';
import { Loading, Private, Public } from 'components';
import ErrorBoundary from 'components/errorBoundary/errorBoundary';
import ErrorLayout from 'containers/error/errorLayout';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import lazyRoute from 'utils/lazy/lazyRoute';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const rotasSistema = sentryCreateBrowserRouter([
  {
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        Component: Public,
        children: [
          {
            path: '/',
            element: <Navigate to='/entrar' replace />,
          },
          {
            path: '/login',
            element: <Navigate to='/entrar' replace />,
          },
          {
            path: 'entrar',
            lazy: lazyRoute(() => import('containers/login/login'), 'Login'),
          },
          {
            path: 'cadastro',
            lazy: lazyRoute(() => import('containers/signup/signup'), 'Signup'),
          },
          {
            path: 'finalizar-contratacao',
            lazy: lazyRoute(
              () => import('containers/completeRegistration/completeRegistration'),
              'CompleteRegistration',
            ),
          },
          {
            path: 'recuperar-senha',
            lazy: lazyRoute(() => import('containers/esqueciMinhaSenha/esqueciMinhaSenha'), 'ForgotPassword'),
          },
          {
            path: 'redefinir-senha',
            lazy: lazyRoute(() => import('containers/redefinirSenha/redefinirSenha'), 'RedefinirSenha'),
          },
        ],
      },
      {
        path: '/',
        Component: Private,
        children: [
          {
            path: 'resumo',
            lazy: lazyRoute(() => import('containers/resumo/resumo'), 'Resumo'),
          },
          {
            path: 'clientes',
            lazy: lazyRoute(() => import('containers/clientesSimplesCte/clientesSimplesCte'), 'Clientes'),
          },
          {
            path: 'grupos-produtores',
            lazy: lazyRoute(() => import('containers/clientesAgronota/clientesAgronota'), 'ClientesAgronota'),
          },
          {
            path: 'config',
            lazy: lazyRoute(() => import('containers/configuracoes/configuracoes'), 'Configuracoes'),
          },
          {
            path: 'config/minha-conta',
            lazy: lazyRoute(() => import('containers/configuracoes/minhaConta/minhaConta'), 'MinhaConta'),
          },
          {
            path: 'config/usuarios',
            lazy: lazyRoute(() => import('containers/configuracoes/usuarios/usuarios'), 'Users'),
          },
          {
            path: 'config/lancamentos',
            lazy: lazyRoute(() => import('containers/configuracoes/lancamentos/lancamentos'), 'Lancamentos'),
          },
          {
            path: 'config/usuarios-sbsistemas',
            lazy: lazyRoute(
              () => import('containers/configuracoes/usuariosSBSistemas/usuariosSBSistemas'),
              'UsuariosSBSistemas',
            ),
          },
          {
            path: 'plano-de-contas',
            lazy: lazyRoute(() => import('containers/planoDeContas/planoDeContas'), 'PlanoDeContas'),
          },
          {
            path: 'produtos',
            lazy: lazyRoute(() => import('containers/produtos/produtos'), 'Produtos'),
          },
          {
            path: 'simulador-ir',
            lazy: lazyRoute(() => import('containers/simuladorIR/simuladorIR'), 'SimuladorIR'),
          },
          {
            path: 'automacoes',
            lazy: lazyRoute(() => import('containers/automacoes/automacoes'), 'Automacoes'),
          },
          {
            path: 'relatorios',
            lazy: lazyRoute(() => import('containers/relatorios/relatorios'), 'Relatorios'),
          },
        ],
      },
      {
        path: '/selecionar-empresa',
        lazy: lazyRoute(() => import('containers/selecionarEmpresa/selecionarEmpresa'), 'SelecionarEmpresa'),
      },
      {
        path: 'termos',
        lazy: lazyRoute(() => import('containers/terms/terms'), 'Terms'),
      },
      {
        path: '/redirect',
        element: <Loading loading />,
      },
      {
        path: '/500',
        element: <ErrorLayout statusCode={500} />,
      },
      {
        path: '*',
        element: <ErrorLayout statusCode={404} />,
      },
    ],
  },
]);
