export enum FinalidadesNatureza {
  /**
   * Não existe essa natureza, foi adicionada pois notas emitidas pelo produtor importadas no sistem podem vir com a finalidade 0
   */
  __IMPORTADO__ = 0,
  NORMAL = 1,
  COMPLEMENTAR = 2,
  AJUSTE = 3,
  DEVOLUCAO = 4,
}

export enum TipoNaturezaOperacao {
  ENTRADA,
  SAIDA,
}

export enum EnumTipoParticipante {
  TODOS,
  CONSUMIDOR_FINAL,
  CONTRIBUINTE,
}

export enum TiposCstIcms {
  CST_00 = 0,
  CST_10 = 10,
  CST_20 = 20,
  CST_40 = 40,
  CST_41 = 41,
  CST_50 = 50,
  CST_51 = 51,
  CST_60 = 60,
  CST_90 = 90,
}

export enum TiposCstPisCofins {
  CST_01 = 1,
  CST_02 = 2,
  CST_03 = 3,
  CST_04 = 4,
  CST_05 = 5,
  CST_06 = 6,
  CST_07 = 7,
  CST_08 = 8,
  CST_09 = 9,
  CST_49 = 49,
  CST_98 = 98,
  CST_99 = 99,
}

export enum EnumNatureza {
  COMPRA_DE_MERCADORIAS,
  DEVOLUCAO_DE_COMPRA,
  DEVOLUCAO_DE_VENDA,
  REMESSA_DE_MERCADORIA,
  RETORNO_DE_MERCADORIA,
  TRANSFERENCIA_DE_ENTRADA,
  TRANSFERENCIA_DE_SAIDA,
  ANULACAO_DE_VALORES,
  VENDA,
  AJUSTE_DE_CREDITO_OU_ESTORNO,
}

export const getFinalidadeNaturezaOp = (finalidade: FinalidadesNatureza) => {
  switch (finalidade) {
    case FinalidadesNatureza.NORMAL:
      return 'Normal';
    case FinalidadesNatureza.COMPLEMENTAR:
      return 'Complementar';
    case FinalidadesNatureza.AJUSTE:
      return 'Ajuste';
    case FinalidadesNatureza.DEVOLUCAO:
      return 'Devolução';
  }
};

export const getTipoNaturezaOp = (tipo: TipoNaturezaOperacao) => {
  switch (tipo) {
    case TipoNaturezaOperacao.ENTRADA:
      return 'Entrada';
    case TipoNaturezaOperacao.SAIDA:
      return 'Saída';
  }
};
