import { Typography } from "@mui/material";
import { Dialog } from '@s_mart/core';
import { Form } from '@s_mart/form';

import { Passos } from './integrarComDominioDialog.types';
import { useIntegrarComDominioDialogStore } from './integrarComDominioDialog.store';

import { TutorialCadastroChaveDominioDialog } from '../tutorialCadastroChaveDominioDialog/tutorialCadastroChaveDominioDialog';
import { useIntegrarComDominioDialog } from './useIntegrarComDominioDialog';

import { ConteudoPrimeiroPasso } from './conteudoPrimeiroPasso/conteudoPrimeiroPasso';
import { ConteudoSegundoPasso } from './conteudoSegundoPasso/conteudoSegundoPasso';

export function IntegrarComDominioDialog() {
  const { tituloDialog, handleSubmit } = useIntegrarComDominioDialog();
  const { open, openTutorialCadastroChave, passo, handleClose } = useIntegrarComDominioDialogStore();

  return (
    <>
      <Dialog open={open} onClose={handleClose} closeIcon disableBackdropClick disableEscapeKeyDown maxWidth='xs'>
        <Dialog.Header>
          <Typography variant='titleMD'>{tituloDialog}</Typography>
        </Dialog.Header>
        <Dialog.Content>
          <Form onSubmit={handleSubmit}>
            {passo === Passos.INTEGRAR_COM_DOMINIO && <ConteudoPrimeiroPasso />}
            {passo === Passos.CONFIRMAR_DADOS && <ConteudoSegundoPasso />}
          </Form>
        </Dialog.Content>
      </Dialog>
      {openTutorialCadastroChave && <TutorialCadastroChaveDominioDialog />}
    </>
  );
}
