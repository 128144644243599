import { lineCopy } from '@s_mart/light-icons';
import { Button, Dialog, LIcon } from '@s_mart/core';

import { QRCodeSVG } from 'qrcode.react';
import { CircularProgress, Unstable_Grid2 as Grid2, Typography } from '@mui/material';

import useModalPix from './useModalPix';
import type { ModalPixProps } from './modalPix.types';

const ModalPix = ({ onClose, ...props }: ModalPixProps) => {
  const { tentativas, copiarPix, pixQrCode, empresaBloqueada, handleJaRealizeiPagamento } = useModalPix(props);

  if (tentativas) {
    return (
      <Dialog open disableBackdropClick disableEscapeKeyDown maxWidth='xs'>
        <Dialog.Header>Aguarde até verificarmos o seu pagamento</Dialog.Header>

        <Dialog.Content style={{ paddingBottom: '1rem' }}>
          <Grid2 container disableEqualOverflow spacing={2}>
            <Grid2 xs={12} display='flex' justifyContent='center'>
              <CircularProgress size={40} thickness={7} />
            </Grid2>

            <Grid2 xs={12} display='flex' justifyContent='center'>
              {tentativas === 1 && (
                <Typography variant='bodySM' sx={(theme) => ({ color: theme.palette.primary.main })}>
                  Conectando com o banco...
                </Typography>
              )}
              {tentativas === 2 && (
                <Typography variant='bodySM' sx={(theme) => ({ color: theme.palette.primary.main })}>
                  Solicitando informações...
                </Typography>
              )}
              {tentativas >= 3 && (
                <Typography variant='bodySM' sx={(theme) => ({ color: theme.palette.primary.main })}>
                  Verificando pagamento...
                </Typography>
              )}
            </Grid2>

            <Grid2 xs={12} marginY='1rem'>
              <Typography variant='bodySM'>
                Assim que confirmado o seu pagamento, o sistema será desbloqueado.
              </Typography>
            </Grid2>

            <Grid2 xs={12}>
              <Typography variant='titleXS'>Este processo geralmente leva menos de 3 minutos.</Typography>
            </Grid2>
          </Grid2>
        </Dialog.Content>
      </Dialog>
    );
  }

  return (
    <Dialog
      open
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      closeIcon={Boolean(empresaBloqueada)}
      onClose={onClose}
    >
      <Dialog.Header>
        <Grid2 disableEqualOverflow container spacing={2}>
          <Grid2 xs={12} display='flex' alignItems='center'>
            <Typography variant='titleMD'>{empresaBloqueada ? 'Desbloqueie' : 'Pague'} agora via Pix</Typography>
          </Grid2>
        </Grid2>
      </Dialog.Header>

      <Dialog.Content>
        <Grid2 container>
          <Grid2 xs={12}>
            <Typography variant='bodyMD'>1. Abra o aplicativo do seu banco</Typography>
          </Grid2>

          <Grid2 xs={12}>
            <Typography variant='bodyMD'>2. Escolha pagar via Pix</Typography>
          </Grid2>

          <Grid2 xs={12} marginBottom='1.5rem'>
            <Typography variant='bodyMD'>3. Escaneie o código QR ou copie e cole o código</Typography>
          </Grid2>

          <Grid2 xs={12} display='flex' justifyContent='center' marginBottom='1rem'>
            <QRCodeSVG value={pixQrCode || ''} size={160} bgColor='#ffffff' fgColor='#000000' level='H' />
          </Grid2>

          <Grid2 xs={12} display='flex' justifyContent='center' marginBottom='1rem'>
            <Button startIcon={<LIcon icon={lineCopy} size='20px' />} variant='outlined' onClick={copiarPix}>
              Copiar código Pix
            </Button>
          </Grid2>

          <Grid2 xs={12}>
            <Typography variant='bodyMD'>Não é necessário enviar o comprovante</Typography>
          </Grid2>
        </Grid2>
      </Dialog.Content>
      <Dialog.Footer>
        {!empresaBloqueada && (
          <Button variant='neutral' onClick={onClose}>
            Voltar
          </Button>
        )}

        {empresaBloqueada ? (
          <Button fullWidth onClick={handleJaRealizeiPagamento}>
            Confirmar pagamento
          </Button>
        ) : (
          <Button onClick={props.onFinalizar}>Finalizar</Button>
        )}
      </Dialog.Footer>
    </Dialog>
  );
};

export default ModalPix;
