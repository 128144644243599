import { Button, LIcon } from '@s_mart/core';

import { Unstable_Grid2 as Grid2, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { valor } from '@s_mart/masks';
import { linePix } from '@s_mart/solid-icons';
import { colorPalette } from '@s_mart/tokens';
import dayjs from 'dayjs';
import type { ModalPendenciaFinanceiraDesktopContentProps } from './modalPendenciaFinanceira.types';

export const ModalPendenciaFinanceiraDesktopContent = ({
  cobrancasEmAberto,
  empresaBloqueada,
  utilizouDesbloqueio,
  onPagarViaBoleto,
  onPagarViaPix,
}: ModalPendenciaFinanceiraDesktopContentProps) => {
  return (
    <Grid2
      container
      display='flex'
      justifyContent='center'
      alignItems='center'
      border={`1px solid ${colorPalette.neutral[30]}`}
      borderRadius='8px'
    >
      <Table sx={{ borderRadius: '1rem' }}>
        <TableHead>
          <TableRow>
            <TableCell size='small' variant='head'>
              <Typography variant='titleXS'>Vencimento</Typography>
            </TableCell>
            <TableCell size='small' variant='head'>
              <Typography variant='titleXS'>Valor</Typography>
            </TableCell>
            <TableCell size='small' variant='head'>
              <Typography variant='titleXS'>Formas de pagamento</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody sx={{ border: '0px' }}>
          {cobrancasEmAberto.map((boleto) => {
            return (
              <TableRow key={boleto.identificador}>
                <TableCell size='medium' sx={{ border: 0 }}>
                  <Typography
                    variant='bodySM'
                    color={dayjs(boleto.dataVencimento).isBefore(dayjs()) ? colorPalette.red[500] : undefined}
                  >
                    {dayjs(boleto.dataVencimento).format('DD/MM/YYYY')}
                  </Typography>
                </TableCell>

                <TableCell size='medium' sx={{ border: 0 }}>
                  <Typography variant='bodySM'>
                    <b>{valor.format(boleto.valor)}</b>
                  </Typography>
                </TableCell>

                <TableCell size='medium' sx={{ display: 'grid', gridTemplateColumns: '1fr auto', border: 0, gap: 2 }}>
                  {boleto.base64Boleto && !utilizouDesbloqueio && (
                    <Button onClick={() => onPagarViaBoleto(boleto)} variant='neutral'>
                      Pagar via boleto
                    </Button>
                  )}

                  {boleto.pixQrCode && (
                    <Button
                      size='small'
                      onClick={() => onPagarViaPix(boleto)}
                      startIcon={<LIcon icon={linePix} removeMargin />}
                    >
                      Pagar via Pix {empresaBloqueada && ' e desbloquear agora'}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Grid2>
  );
};
