import { Typography } from "@mui/material";
import { useTheme } from '@emotion/react';

import { Button, LIcon } from '@s_mart/core';
import { lineInfoCircle } from '@s_mart/solid-icons';

import useAuthStore from 'store/auth/auth';
import { BannerContainer, LeftIndicator } from './bannerContratacao.styles';
import { ContratarPlanoDialog } from './contratarPlanoDialog/contratarPlanoDialog';
import { useBannerContratacao } from './useBannerContratacao';

export function BannerContratacao() {
  const [nomePlano, dataContratacao] = useAuthStore((s) => [s.nomePlano, s.meusDados?.dataContratacao]);

  const { contratarPlanoDialogOpen, handleCloseContratarPlanoDialog, handleOpenDialogContratarPlano } =
    useBannerContratacao();

  const { palette } = useTheme();

  if (dataContratacao !== null) {
    return null;
  }

  return (
    <>
      <BannerContainer>
        <LeftIndicator />
        <LIcon icon={lineInfoCircle} color={palette.primary.main} size='20px' removeMargin />
        <Typography variant='bodySM'>
          Contratar plano {nomePlano}
          {/* por {valor.format(precoPlano)} */} ao CNPJ cadastrado?
        </Typography>
        <Button size='small' onClick={handleOpenDialogContratarPlano}>
          Contratar agora
          {/* por {valor.format(precoPlano)} */}
        </Button>
      </BannerContainer>
      {contratarPlanoDialogOpen && (
        <ContratarPlanoDialog
          open={contratarPlanoDialogOpen}
          handleClose={handleCloseContratarPlanoDialog}
          nomePlano={nomePlano}
        />
      )}
    </>
  );
}
