import { z } from 'zod';
import { SituacaoCobranca } from './listarCobrancas';
import { ZodApiResponseParser } from 'api/zodApi';

const schemaConsultarCobrancaResponse = z.object({
  data: z.nativeEnum(SituacaoCobranca),
});

export const parseConsultarCobrancaResponse = ZodApiResponseParser.createParser(schemaConsultarCobrancaResponse);

export type ConsultarCobrancaResponse = z.infer<typeof schemaConsultarCobrancaResponse>;
