// Variações de props do ButtonPendencia
export const mapButtonPendenciaProps = new Map([
  [
    'cobrancaDisponivel',
    {
      children: 'Verificar boleto disponível',
      estiloBotao: 0,
    },
  ],
  [
    'cobrancaProximaDoVencimento',
    {
      children: 'Verificar boleto próximo do vencimento',
      estiloBotao: 1,
    },
  ],
  [
    'cobrancaVenceHoje',
    {
      children: 'Verificar boleto que vence hoje',
      estiloBotao: 1,
    },
  ],
  [
    'cobrancaVencida',
    {
      children: 'Verificar boleto vencido e evitar bloqueio',
      estiloBotao: 2,
    },
  ],
  [
    'sistemaBloqueado',
    {
      children: 'Sistema bloqueado. Verificar boletos em aberto',
      estiloBotao: 2,
    },
  ],
] as const);
