import ReactGA from 'react-ga4';
import useAuthStore from 'store/auth/auth';
import { isStaging } from 'utils/isStaging';
import { useCallback } from 'react';
import { Config } from 'config';

type _AnalyticsConfig = {
  action: string;
};

export type AnalyticsEventTracker = (config: _AnalyticsConfig) => void;

const analyticsIds: Record<number, string> = {
  // SIMPLESCTE
  1: 'G-WD5CFWWPWW',
  // AGRONOTA
  2: 'G-4VRQ6XDYG3',
};

export const useAnalytics = () => {
  const perfil = useAuthStore((s) => s.perfil);

  const initialize = useCallback(() => {
    if (
      !isStaging() &&
      perfil.emp !== null &&
      analyticsIds[Config.systemData.code] !== null &&
      !ReactGA.isInitialized
    ) {
      ReactGA.initialize(analyticsIds[Config.systemData.code], {
        gaOptions: {
          userId: perfil.emp.toString(),
        },
      });
    }
  }, [perfil.emp]);

  const analyticsEventTracker = ({ action }: _AnalyticsConfig) => {
    if (!isStaging()) {
      ReactGA.event({ action: action, category: 'event' });
    }
  };

  return {
    initialize,
    analyticsEventTracker,
  };
};

export default useAnalytics;
