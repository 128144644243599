import { Config } from 'config';
import { EnumSistemas } from 'constants/sistemas';
import { useState } from 'react';
import { ModalMultiEmpresa } from 'shared/modalMultiEmpresa/modalMultiEmpresa';
import { AppBarAgronota } from './appBarAgronota';
import { AppBarSimplesCTe } from './appBarSimplesCTe';

export const AppBar = () => {
  const [modalMultiempresa, setModalMultiempresa] = useState(false);

  return (
    <>
      {modalMultiempresa && <ModalMultiEmpresa onClose={() => setModalMultiempresa(false)} />}
      {Config.systemData.code === EnumSistemas.AGRONOTA && (
        <AppBarAgronota setModalMultiempresa={setModalMultiempresa} />
      )}
      {Config.systemData.code === EnumSistemas.SIMPLESCTE && (
        <AppBarSimplesCTe setModalMultiempresa={setModalMultiempresa} />
      )}
    </>
  );
};
