import { ZodApiResponseParser } from 'api/zodApi';
import { z } from 'zod';
import { schemaListarOperacoesItem } from './listarOperacoes';
import { EnumTipoParticipante, FinalidadesNatureza, TipoNaturezaOperacao } from 'constants/naturezasOperacao';

const _schemaEstadoNaturezaBuscar = z.object({
  codigoEstado: z.number(),
  codigoRegra: z.number(),
  codigoRegraEstado: z.number(),
  uf: z.string(),
});

const schemaTributacoesRegraNaturezaBuscar = z.object({
  codigoTributacao: z.number(),
  aliquotaDiferimento: z.number().nullable(),
  cBenef: z.string().nullable(),
  cofinsAliquota: z.number().nullable(),
  cofinsAliquotaReais: z.number().nullable(),
  cofinsCst: z.number().nullable(),
  cofinsPercentualBc: z.number().nullable(),
  icmsAliquota: z.number().nullable(),
  icmsCst: z.number().nullable(),
  icmsDesoneradoMotivo: z.number().nullable(),
  icmsPercBc: z.number().nullable(),
  icmsStAliquota: z.number().nullable(),
  icmsStModalidadeBc: z.number().nullable(),
  observacoes: z.string().nullable(),
  modBC: z.number().nullable(),
  origemMercadoria: z.number().nullable(),
  pisAliquota: z.number().nullable(),
  pisAliquotaReais: z.number().nullable(),
  pisCst: z.number().nullable(),
  pisPercentualBc: z.number().nullable(),
  tipoParticipante: z.nativeEnum(EnumTipoParticipante),
  valorUnitarioPauta: z.number().nullable(),
});

const _schemaRegraNaturezaBucar = z.object({
  cfop: z.number(),
  cfopNome: z.string(),
  codigoOperacao: z.number(),
  codigoRegra: z.number(),
  estadosDestino: z.array(_schemaEstadoNaturezaBuscar),
  estadosOrigem: z.array(_schemaEstadoNaturezaBuscar),
  ncms: z.array(
    z.object({
      codigoRegra: z.number(),
      codigoRegraNcm: z.number(),
      ncm: z.string(),
      nome: z.string(),
    }),
  ),
  produtos: z.array(
    z.object({
      codigoProduto: z.number(),
      codigoRegra: z.number(),
      codigoRegraProduto: z.number(),
      nomeProduto: z.string(),
    }),
  ),
  remessa: z.number(),
  retorno: z.number(),
  tributacoes: z.array(schemaTributacoesRegraNaturezaBuscar),
});

const _schemaNaturezaBuscar = z.object({
  codigoNatureza: z.number(),
  finalidade: z.nativeEnum(FinalidadesNatureza),
  naturezaNome: z.string(),
  operacao: schemaListarOperacoesItem,
  tipo: z.nativeEnum(TipoNaturezaOperacao),
  regra: z.array(_schemaRegraNaturezaBucar),
});
// #################################
// ########### PARSERS #############
// #################################

export const parseBuscarNaturezaOperacaoResponse = ZodApiResponseParser.createParser(
  z.object({
    data: _schemaNaturezaBuscar,
  }),
);

export type NaturezaOperacaoBuscar = z.infer<typeof _schemaNaturezaBuscar>;

export type RegraNaturezaBuscar = z.infer<typeof _schemaRegraNaturezaBucar>;

export type EstadoNaturezaBuscar = z.infer<typeof _schemaEstadoNaturezaBuscar>;

export type TributacaoRegraNaturezaBuscar = z.infer<typeof schemaTributacoesRegraNaturezaBuscar>;
