import { ZodApiResponseParser } from 'api/zodApi';
import { FinalidadesNatureza, TipoNaturezaOperacao } from 'constants/naturezasOperacao';
import { z } from 'zod';

export const schemaListarOperacoesItem = z.object({
  codigo: z.number(),
  descricao: z.string(),
  nome: z.string(),
  finalidade: z.nativeEnum(FinalidadesNatureza),
  tipo: z.nativeEnum(TipoNaturezaOperacao),
  remessa: z.number(),
  retorno: z.number(),
  transferencia: z.number(),
});

const schemaListarOperacoes = z.object({
  data: z.array(schemaListarOperacoesItem),
});

export const parseListarOperacoesResponse = ZodApiResponseParser.createParser(schemaListarOperacoes);

export type ListarOperacoesItem = z.infer<typeof schemaListarOperacoesItem>;
