import { useEffect, useMemo } from 'react';

import useAppStore from 'store/app/app';
import useAuthStore from 'store/auth/auth';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { isStaging } from 'utils/isStaging';
import { EnumSistemas } from 'constants/sistemas';
import { Config } from 'config';

dayjs.extend(relativeTime);

export function usePrivate() {
  const [mostrarOnboarding, setMostrarOnboarding] = useAppStore((state) => [
    state.mostrarOnboarding,
    state.setMostrarOnboarding,
  ]);

  const [token, configuracoes, perfil, meusDados] = useAuthStore((state) => [
    state.token,
    state.configuracoes,
    state.perfil,
    state.meusDados,
  ]);

  // validação do onboarding Agronota
  useEffect(() => {
    if (Config.systemData.code !== EnumSistemas.AGRONOTA) {
      return;
    }

    if (configuracoes?.onboarding === 0) {
      if (isStaging()) {
        setMostrarOnboarding(true);
      } else if (
        (perfil?.emp !== null && perfil.emp > 9801) ||
        perfil.emp === 4962 ||
        perfil.emp === 8416 ||
        perfil.emp === 9728
      ) {
        setMostrarOnboarding(true);
      }
    }
  }, [configuracoes, perfil]);

  return {
    mostrarOnboarding,
    token,
    meusDados,
  };
}
