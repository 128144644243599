import { memo } from 'react';
import { ButtonPendencia } from './botaoPendenciaFinanceira.styles';
import { useBotaoPendenciaFinanceira } from './useBotaoPendenciaFinanceira';

export const BotaoPendenciaFinanceira = memo(() => {
  const { botaoPendenciaFinanceiraProps, handleButtonClick } = useBotaoPendenciaFinanceira();

  if (botaoPendenciaFinanceiraProps === null) {
    return null;
  }
  return (
    <ButtonPendencia
      variant='outlined'
      onClick={handleButtonClick}
      data-estilo-botao={botaoPendenciaFinanceiraProps.estiloBotao}
    >
      {botaoPendenciaFinanceiraProps.children}
    </ButtonPendencia>
  );
});
