import { api } from 'api/api';
import { axiosInstance } from 'api/axiosConfig';
import { jwtDecode } from 'jwt-decode';
import useAppStore from 'store/app/app';
import { create } from 'zustand';
import type { AuthState, AuthStore, Recursos } from './auth.types';
import { useClientesStore } from 'store/clientes/clientes';
import { usePlanoDeContasStore } from 'store/planoDeContas/planoDeContasStore';
import { useUsuariosStore } from 'store/usuarios/usuarios';
import type { TAGS_APRESENTACOES } from 'api/configuracoes/configuracoes.types';
import { produce } from 'immer';
import Cookies from 'js-cookie';
import { EnumSistemas, sistemas } from 'constants/sistemas';
import { ROTAS_PRIVADAS } from 'containers/hocs/privateRouteMiddleware/permissoesRotasPrivadas';
import { EnumAutomacaoAgronota } from 'constants/configuracoes';
import useCobrancasStore from 'store/cobrancas/useCobrancasStore';

const initialState: AuthState = {
  token: null,
  loginResponse: null,
  bloqueado: null,
  nomePlano: '',
  rotaPrivadaInicial: '',
  recursosDisponiveis: null,
  configuracoes: null,
  empresaAtual: null,
  meusDados: null,
  authLoading: false,
  configuracoesUsuario: null,
  perfil: {
    nome: '',
    email: '',
    emp: null as unknown as number,
    sub: null as unknown as number,
    multi: false,
    razao_social: '',
    sistemas: [],
    opera_sistema: 0,
    paga_sistema: 0,
    indica_cliente: 0,
  },
};

const useAuthStore = create<AuthStore>((set, get) => ({
  ...initialState,
  setAuthLoading: (payload) => {
    useAppStore.getState().setLoading(payload);
    set({ authLoading: payload });
  },
  login: async (payload, navigate) => {
    try {
      get().setAuthLoading(true);
      const { data } = await api.auth.login(payload);

      set({
        loginResponse: data,
        bloqueado: data.situacao === 2,
      });

      get().redirecionarSeMultiEmpresa(data.token, navigate);

      get().tratarDadosToken(data.token, payload.remember);
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    } finally {
      get().setAuthLoading(false);
    }
  },
  loginComToken: async ({ token, remember }) => {
    try {
      get().setAuthLoading(true);
      if (!token) return;
      const currentToken = get().token;

      if (currentToken) {
        get().limparTodosStores();
      }

      get().tratarDadosToken(token, remember);
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    } finally {
      get().setAuthLoading(false);
    }
  },
  logout: async () => {
    try {
      get().setAuthLoading(true);

      await api.auth.logout();

      get().limparTodosStores();
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    } finally {
      get().setAuthLoading(false);
    }
  },
  redirecionarSeMultiEmpresa: (token, navigate) => {
    const _tokenDescodificado = jwtDecode<AuthState['perfil']>(token);
    if (_tokenDescodificado === null) {
      return;
    }
    const _multiEmpresa = _tokenDescodificado.multi;

    const _empresaAtual = _tokenDescodificado.emp;

    if (_multiEmpresa && !_empresaAtual) {
      navigate('/selecionar-empresa');
    }
  },
  redirecionarRotaPrivadaInicial() {
    const multiEmpresa = get().perfil.multi;

    let rotaInicial: string = ROTAS_PRIVADAS.RESUMO;

    if (multiEmpresa && !get().empresaAtual) {
      rotaInicial = '/selecionar-empresa';
    } else {
      const _nomeSistema = window.location.hostname.split('.')[1].toLowerCase();
      const sistemaAtual = sistemas[_nomeSistema];

      const _recursos = get().recursosDisponiveis;

      if (sistemaAtual.code === EnumSistemas.AGRONOTA) {
        if (_recursos !== null && Object.values(_recursos).length === 1 && _recursos['simulador-ir-parceiro']) {
          rotaInicial = ROTAS_PRIVADAS.SIMULADOR_IR;
        } else {
          rotaInicial = ROTAS_PRIVADAS.RESUMO;
        }
      }
      if (sistemaAtual.code === EnumSistemas.SIMPLESCTE) {
        rotaInicial = ROTAS_PRIVADAS.CLIENTES;
      }
    }

    set({ rotaPrivadaInicial: rotaInicial });

    if (get().configuracoes !== null && get().meusDados !== null && get().recursosDisponiveis !== null) {
      if (['/entrar', '/selecionar-empresa', '/redirect'].includes(window.location.pathname)) {
        window.location.assign(rotaInicial);
      }
    }
  },
  trocarEmpresaAtual: async (emp) => {
    const handleErrors = useAppStore.getState().handleErrors;

    try {
      get().setAuthLoading(true);
      const { data: result } = await api.empresa.trocarEmpresa(emp);

      Cookies.set('id_token', result.token);

      if (window.location.pathname === '/selecionar-empresa') {
        window.location.assign('/resumo');
      } else {
        window.location.reload();
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      get().setAuthLoading(false);
    }
  },
  carregarDadosSistema: async () => {
    const empresaAtual = get().empresaAtual;

    if (!empresaAtual) {
      return;
    }
    try {
      const listarCobrancas = useCobrancasStore.getState().listarCobrancas;
      await Promise.all([
        get().buscarConfiguracoes(true),
        get().buscarMeusDados(true),
        get().buscarRecursos(true),
        get().buscarConfiguracoesUsuario(),
        listarCobrancas(),
      ]);
    } catch (error) {
      useAppStore.getState().handleErrors(error);
      get().limparTodosStores();
      window.location.replace('/entrar');
    }
  },
  tratarDadosToken: async (token, remember) => {
    try {
      get().setAuthLoading(true);

      axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;

      Cookies.set('id_token', token, {
        expires: remember ? 7 : 1,
      });

      const decoded = jwtDecode<AuthState['perfil']>(token);

      if (decoded) {
        set({
          token,
          perfil: decoded,
          empresaAtual: decoded.emp || null,
        });
      }

      if (get().configuracoes === null && get().meusDados === null && get().recursosDisponiveis === null) {
        await get().carregarDadosSistema();
      }

      get().redirecionarRotaPrivadaInicial();
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    } finally {
      get().setAuthLoading(false);
    }
  },
  buscarConfiguracoesUsuario: async () => {
    try {
      const { data: userConfigResponse } = await api.usuarios.buscarConfiguracoes();
      set({
        configuracoesUsuario: userConfigResponse.data,
      });
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    }
  },
  buscarConfiguracoes: async (throwError) => {
    try {
      const { data: configResponse } = await api.configuracoes.buscar();

      set({
        configuracoes: {
          ...configResponse.data,
          apresentacoes:
            configResponse.data?.apresentacoes && configResponse.data.apresentacoes !== null
              ? JSON.parse(configResponse.data.apresentacoes)
              : null,
        },
      });
    } catch (error: any) {
      if (throwError) {
        throw new Error(error);
      }
      useAppStore.getState().handleErrors(error);
    }
  },
  buscarRecursos: async (throwError) => {
    try {
      const { data } = await api.empresa.buscarRecursos();

      const _recursosDisponiveis = data.data.recursos.filter((recurso: Recursos) => recurso.recursoContratado);
      const _recursosDisponiveisMapeados = _recursosDisponiveis.map(({ slugRecurso }: Recursos) => [slugRecurso, true]);

      const recursosDisponiveis = Object.fromEntries(_recursosDisponiveisMapeados);

      set({
        recursosDisponiveis,
        nomePlano: data.data.nomePlano,
      });
    } catch (error: any) {
      if (throwError) {
        throw new Error(error);
      }
      useAppStore.getState().handleErrors(error);
    }
  },
  buscarMeusDados: async (throwError) => {
    try {
      const { data } = await api.empresa.buscarDados();

      set({
        meusDados: data.data,
        bloqueado: data.data.situacao === 2,
      });
    } catch (error: any) {
      if (throwError) {
        throw new Error(error);
      }
      useAppStore.getState().handleErrors(error);
    }
  },
  verificarTokenConvite: async ({ token, onError }) => {
    try {
      useAppStore.getState().setLoading(true);

      await api.auth.verificarTokenConvite(token);

      useAppStore.getState().setLoading(false);
    } catch (error) {
      onError();
      useAppStore.getState().handleErrors(error);
    }
  },
  signup: async ({ onSuccess: callback, ...payload }) => {
    try {
      useAppStore.getState().setLoading(true);

      const { data } = await api.auth.signup(payload);

      if (data.email) {
        callback?.(data.email);
      }

      useAppStore.getState().setLoading(false);
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    }
  },
  verificarTokenRegistro: async ({ onError, ...payload }) => {
    try {
      useAppStore.getState().setLoading(true);

      await api.auth.verificarTokenCompletarRegistro(payload);

      useAppStore.getState().setLoading(false);
    } catch (error) {
      onError();
      useAppStore.getState().handleErrors(error);
    }
  },
  completarRegistro: async (payload, callback) => {
    try {
      useAppStore.getState().setLoading(true);

      await api.auth.completarRegistro(payload);

      callback?.(payload.email);
      useAppStore.getState().setLoading(false);
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    }
  },
  esqueciSenha: async (payload) => {
    try {
      useAppStore.getState().setLoading(true);

      const { data } = await api.auth.enviarEmailRecuperarSenha(payload);

      useAppStore.getState().setNotification({
        title: data.messages[0],
      });

      useAppStore.getState().setLoading(false);
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    }
  },
  verificarTokenEsqueciSenha: async ({ token, onError }) => {
    try {
      useAppStore.getState().setLoading(true);

      await api.auth.verificarTokenRedefinirSenha(token);

      useAppStore.getState().setLoading(false);
    } catch (error) {
      onError();
      useAppStore.getState().handleErrors(error);
    }
  },
  resetarSenha: async (payload, callback) => {
    try {
      useAppStore.getState().setLoading(true);

      const { data } = await api.auth.redefinirSenha(payload);

      useAppStore.getState().setNotification({
        title: data.messages[0],
      });

      callback?.();

      useAppStore.getState().setLoading(false);
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    }
  },
  editarConfiguracoesSimuladorIR: async (payload) => {
    try {
      const { status } = await api.configuracoes.editarConfiguracoesSimuladorIR({
        usuarios: payload.usuariosApresentacaoSimuladoIR ?? null,
      });

      if (status === 204) {
        set({ configuracoes: payload });
      }
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    }
  },

  marcarTourComoVisualizado: async (_tag: TAGS_APRESENTACOES) => {
    // TODO: implementar novamente quando tiver novos tours
    // Foi removido pois estava dando erro de tipagem
  },

  configurarParceiroComoMigrado() {
    set({
      configuracoes: produce(get().configuracoes, (draft) => {
        if (draft) draft.automacaoAgronota = EnumAutomacaoAgronota.CentralFinanceira;
      }),
    });
  },

  limparStore: () => {
    set(initialState);

    axiosInstance.defaults.headers.common.Authorization = '';

    Cookies.remove('id_token');
  },
  limparTodosStores() {
    get().limparStore();
    useClientesStore.getState().limparStore();
    usePlanoDeContasStore.getState().limparStore();
    useUsuariosStore.getState().limparStore();
  },
}));

export default useAuthStore;
