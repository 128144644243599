import type { FieldValues, UseFormReturn } from 'react-hook-form';

import type { Cliente } from 'store/clientes/clientes.types';
import type { DadosDominio } from 'api/dominio/dominio.types';
import type { ItemListarClientesSimplesCteResponse } from 'api/clientes/schemas/listarClientesSimplesCte';

export enum Passos {
  INTEGRAR_COM_DOMINIO = 0,
  CONFIRMAR_DADOS = 1,
}

export interface IntegrarComDominioDialogStore {
  open: boolean;
  dadosCliente: ItemListarClientesSimplesCteResponse | null;
  dadosIntegracao: DadosDominio['data'] | null;

  passo: Passos;

  handleOpen: (dadosCliente: ItemListarClientesSimplesCteResponse) => void;
  handleClose: () => void;
  handleProximoPasso: () => void;
  handleVoltarPasso: () => void;
  setDadosIntegracao: (dadosIntegracao: DadosDominio['data']) => void;

  openTutorialCadastroChave: boolean;
  handleOpenTutorialCadastroChave: () => void;
  handleCloseTutorialCadastroChave: () => void;
}

export interface IntegrarComDominioFormValues {
  chaveDominio: string;
  erroChaveDominio?: string;
}

export interface HandleSubmitProps {
  values: IntegrarComDominioFormValues;
  methods: UseFormReturn<FieldValues>;
}
