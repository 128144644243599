import { ZodApiResponseParser } from 'api/zodApi';
import { z } from 'zod';

const schemaBaixarBoletoResponse = z.object({
  data: z.object({
    boleto: z.string().base64(),
  }),
});

export const parseBaixarBoletoResponse = ZodApiResponseParser.createParser(schemaBaixarBoletoResponse);

export type BaixarBoletoResponse = z.infer<typeof schemaBaixarBoletoResponse>;
