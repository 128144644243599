import { useState } from 'react';

import type { UseModalPixProps } from './modalPix.types';
import useAppStore from 'store/app/app';
import useAuthStore from 'store/auth/auth';
import { api } from 'api/api';
import { useClientesStore } from 'store/clientes/clientes';
import { delay } from 'utils/delay';
import { SituacaoCobranca } from 'api/empresa/schemas/listarCobrancas';

const useModalPix = ({
  cobranca,
  onFinalizar,
  cobrancasEmAberto,
  onPagamentoRealizado,
  onPagamentoNaoIdentificado,
}: UseModalPixProps) => {
  const empresaBloqueada = useAuthStore((state) => state.bloqueado);
  const toggleSnackBar = useAppStore((state) => state.setNotification);
  const setLoading = useAppStore((state) => state.setLoading);

  const [tentativas, setTentativas] = useState(0);

  const pixQrCode = cobranca.pixQrCode!;

  const copiarPix = () => {
    navigator.clipboard.writeText(pixQrCode);

    toggleSnackBar({
      title: 'Código Pix copiado',
      variant: 'success',
    });
  };

  const handleJaRealizeiPagamento = async () => {
    try {
      await handleConsultarPagamento();

      onPagamentoRealizado();
    } catch (_) {
      if (cobrancasEmAberto.length === 1 && !cobranca.dataDesbloqueio) {
        handleDesbloquear();
      } else {
        onPagamentoNaoIdentificado(cobranca);
      }
    }
  };

  const handleConsultarPagamento = () => {
    return new Promise<void>((resolve, reject) => {
      const recursive = async (tentativas: number) => {
        setTentativas(tentativas);

        const pago = await consultarPagamento();

        if (pago) {
          resolve();
        } else {
          if (tentativas >= 4) {
            reject();
          } else {
            await delay(1000 * 60);

            recursive(tentativas + 1);
          }
        }
      };

      recursive(1);
    });
  };

  const consultarPagamento = async () => {
    const pagamentoRealizado = await api.empresa
      .consultarCobranca(cobranca.codigo)
      .then((response) => response.data.data === SituacaoCobranca.LIQUIDADO)
      .catch(() => false);

    return pagamentoRealizado;
  };
  const [buscarMeusDados, buscarConfiguracoesUsuario] = useAuthStore((state) => [
    state.buscarMeusDados,
    state.buscarConfiguracoesUsuario,
  ]);

  const buscarClientes = useClientesStore((state) => state.buscarClientes);

  const handleDesbloquear = async () => {
    try {
      setLoading(true);
      await api.empresa.solicitarDesbloqueio();

      await Promise.all([buscarMeusDados(), buscarConfiguracoesUsuario(), buscarClientes()]);

      toggleSnackBar({
        title: 'Sistema desbloqueado',
        variant: 'success',
      });

      onFinalizar();
    } catch (_) {
      onPagamentoNaoIdentificado(cobranca);
    } finally {
      setLoading(false);
    }
  };

  return { tentativas, empresaBloqueada, copiarPix, pixQrCode, handleJaRealizeiPagamento };
};

export default useModalPix;
