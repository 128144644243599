import { create } from 'zustand';

import type CobrancasStore from './cobrancas.types';
import type { CobrancaEmAberto, CobrancasStoreState } from './cobrancas.types';
import { api } from 'api/api';
import { SituacaoCobranca, type ItemListarCobrancasResponse } from 'api/empresa/schemas/listarCobrancas';
import useAppStore from 'store/app/app';

export const cobrancasStoreInitialState: CobrancasStoreState = {
  modalPendenciasFinanceira: false,
  base64Boleto: null,
  cobrancasEmAberto: null,
};

const useCobrancasStore = create<CobrancasStore>((set) => ({
  ...cobrancasStoreInitialState,
  listarCobrancas: async () => {
    try {
      const cobrancas = await api.empresa.buscarCobrancas();

      if (!cobrancas?.data) {
        return;
      }

      const faturasAPagar = cobrancas.data.data.filter((cobranca: ItemListarCobrancasResponse) =>
        [SituacaoCobranca.EMITIDO, SituacaoCobranca.BAIXADO].includes(cobranca.situacao),
      );

      if (!faturasAPagar.length) {
        return set({ cobrancasEmAberto: [] });
      }

      const cobrancasEmAberto: CobrancaEmAberto[] = await Promise.all(
        faturasAPagar.map((fatura): Promise<CobrancaEmAberto> => {
          return api.empresa
            .baixarBoleto(faturasAPagar[0].identificador)
            .then((response): CobrancaEmAberto => ({ ...fatura, base64Boleto: response.data.data.boleto || null }));
        }),
      );

      set({
        base64Boleto: cobrancasEmAberto[0].base64Boleto || null,
        cobrancasEmAberto,
      });
    } catch (err) {
      useAppStore.getState().handleErrors(err);
    }
  },
  setModalPendenciasFinanceiras(bool) {
    set({ modalPendenciasFinanceira: bool });
  },
  resetState: () => set(cobrancasStoreInitialState),
}));

export default useCobrancasStore;
