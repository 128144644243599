import { create } from 'zustand';
import type { AppStates, AppStore } from './app.types';
import parseError from './handleError/parseError';
import { ResponseType, getResponseTypeLabel } from './handleError/parseError.static';
import useAuthStore from 'store/auth/auth';

const initialState: AppStates = {
  notification: null,
  loading: false,
  mostrarOnboarding: false,
  mostrarAvisoPrimeiroCliente: false,
  modalClienteOnboarding: null,
};

const useAppStore = create<AppStore>((set) => ({
  ...initialState,
  setNotification: (notification) => {
    set((state) => ({ ...state, notification }));
    if (notification && typeof notification !== 'function') {
      setTimeout(() => set((state) => ({ ...state, notification: null })), notification.autoHideDuration ?? 3000);
    }
  },
  setLoading: (loading) => set((state) => ({ ...state, loading })),
  handleErrors: (error) => {
    const parsedError = parseError(error);
    if (parsedError.type === ResponseType.AUTH_ERROR) {
      useAuthStore.getState().limparStore();

      window.location.replace('/entrar');
    }
    set({
      notification: {
        title: parsedError.message,
        message: parsedError.description,
        variant: getResponseTypeLabel(parsedError.type),
        autoHideDuration: 8000,
      },
      loading: false,
    });
  },
  setMostrarOnboarding: (mostrar) => set({ mostrarOnboarding: mostrar }),
  setModalClienteOnboarding: (cliente) => set({ modalClienteOnboarding: cliente }),
  setMostrarAvisoPrimeiroCliente: (mostrar) => set({ mostrarAvisoPrimeiroCliente: mostrar }),
  limparStore: () => set(initialState),
}));

export default useAppStore;
