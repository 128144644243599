import { type DataResponse, axiosInstance } from 'api/axiosConfig';
import type {
  InserirCertificadoBody,
  InserirClienteBody,
  ListarClientesParams,
  ListarClientesUsuariosParams,
} from './clientes.types';
import type { BaixarXMLPayload, CompartilharXMLPayload } from 'store/clientes/clientes.types';
import { API_URL_AGRONOTA, API_URL_SIMPLESCTE } from 'api/urls';
import {
  type ListarClientesSimplesCteParams,
  parseListarClientesSimplesCteParams,
  parseListarClientesSimplesCteResponse,
} from './schemas/listarClientesSimplesCte';

export const clientes = {
  listarSimplesCte: (params?: ListarClientesSimplesCteParams) =>
    axiosInstance
      .get('/parceiro/clientes', { ...parseListarClientesSimplesCteParams(params) })
      .then(parseListarClientesSimplesCteResponse),

  listar: (params?: ListarClientesParams) => axiosInstance.get('/parceiro/clientes', { params: params }),

  listarUsuariosClientes: (params?: ListarClientesUsuariosParams) =>
    axiosInstance.get('/parceiro/clientes/usuarios', { params: params }),

  // usado Partial porque não temos certeza do que é obrigatorio nesse body
  inserir: (body: Partial<InserirClienteBody>) => axiosInstance.post<DataResponse<number>>('/parceiro/company', body),

  inserirCertificado: (body: InserirCertificadoBody) =>
    axiosInstance.post(`${API_URL_AGRONOTA}/parceiro/configuracoes/certificado`, body),

  // AGRONOTA
  cadastrosPadrao: (emp: number) => axiosInstance.post(`${API_URL_AGRONOTA}/parceiro/${emp}/cadastro-padrao`),

  inserirNumerario: (emp: number) => axiosInstance.post(`${API_URL_AGRONOTA}/parceiro/${emp}/inserir-numerario`),
  // XML - SIMPLES CTE
  baixarXML: (body: BaixarXMLPayload) => axiosInstance.post(`${API_URL_SIMPLESCTE}/v2/parceiro/cte/download`, body),

  enviarEmailXML: (body: CompartilharXMLPayload) =>
    axiosInstance.post(
      `/parceiro/enviardocsemail/${body.codigo}?datainicial=${body.data_inicial}&datafinal=${body.data_final}&sistema=${body.sistema}`,
      body.emails,
    ),
};
