import { Typography } from "@mui/material";
import { Button, Dialog } from '@s_mart/core';
import { Form } from 'react-final-form';
import StepDadosCadastrais from 'shared/modalContratarSimulador/modalStepsContratacao/stepDadosCadastrais/stepDadosCadastrais';

import type { ContratarPlanoDialogProps } from './contratarPlanoDialog.types';

import { useContratarPlano } from './useContratarPlano';

export function ContratarPlanoDialog({ open, handleClose, nomePlano }: ContratarPlanoDialogProps) {
  const { etapa, initialValues, handleSubmitContratacao } = useContratarPlano({ handleClose });

  return (
    <Form
      onSubmit={handleSubmitContratacao}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <Dialog open={open} onClose={handleClose} disableBackdropClick disableEscapeKeyDown closeIcon maxWidth='xs'>
          <Dialog.Header>
            <Typography variant='titleMD'>
              {etapa == 0 ? 'Confirme seus dados cadastrais' : 'Contratar plano'}
            </Typography>
          </Dialog.Header>
          <Dialog.Content>
            {etapa === 0 ? <StepDadosCadastrais /> : null}
            {etapa === 1 ? (
              <Typography variant='bodySM'>Você está prestes contratar o plano {nomePlano}.</Typography>
            ) : null}
          </Dialog.Content>
          <Dialog.Footer>
            <Button onClick={handleClose} variant='neutral'>
              Cancelar
            </Button>
            <Button onClick={handleSubmit}>{etapa === 0 ? 'Próximo' : 'Contratar'}</Button>
          </Dialog.Footer>
        </Dialog>
      )}
    />
  );
}
