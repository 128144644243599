import { useEffect, useState } from 'react';

import { api } from 'api/api';
import { useIntegradoComDominioDialogStore } from './useIntegradoComDominioDialog.store';
import useAppStore from 'store/app/app';

export function useIntegradoComDominioDialog() {
  const [handleErrors, setLoading] = useAppStore((s) => [s.handleErrors, s.setLoading]);
  const { dadosCliente, setDadosIntegracao, handleClose } = useIntegradoComDominioDialogStore();

  const [removerIntegracaoDialog, setRemoverIntegracaoDialog] = useState(false);

  function handleOpenRemoverIntegracaoDialog() {
    setRemoverIntegracaoDialog(true);
  }

  function handleCloseRemoverIntegracaoDialog() {
    setRemoverIntegracaoDialog(false);
  }

  async function handleBuscarDadosDominio() {
    setLoading(true);
    try {
      if (dadosCliente?.codigo) {
        const { data } = await api.dominio.buscarDados(dadosCliente?.codigo);

        if (data.data === null) {
          throw new Error('Não foram encontrados dados de integração com a Domínio');
        } else {
          setDadosIntegracao(data.data);
        }
      } else {
        throw new Error('Não foi possível buscar os dados da Domínio');
      }
    } catch (err) {
      handleErrors(err);
      handleClose();
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleBuscarDadosDominio();
  }, []);

  return {
    removerIntegracaoDialog,
    handleOpenRemoverIntegracaoDialog,
    handleCloseRemoverIntegracaoDialog,
  };
}
