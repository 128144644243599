import { Grid, Typography } from "@mui/material";
import { Button, Dialog } from '@s_mart/core';

import type { RemoverIntegracaoDialogProps } from './removerIntegracaoDialog.types';
import { useRemoverIntegracaoDialog } from './useRemoverIntegracaoDialog';

export function RemoverIntegracaoDialog({ open, handleClose }: RemoverIntegracaoDialogProps) {
  const { handleRemoverIntegracao } = useRemoverIntegracaoDialog({ handleClose });

  return (
    <Dialog open={open} onClose={handleClose} closeIcon disableBackdropClick disableEscapeKeyDown maxWidth='xs'>
      <Dialog.Header>
        <Typography variant='titleMD'>Remover integração com a Domínio?</Typography>
      </Dialog.Header>
      <Dialog.Content>
        <Grid container gap={2}>
          <Grid item xs={12}>
            <Typography variant='bodySM'>
              Se remover a integração, precisará reinserir a chave para integrar novamente.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            Tem certeza que deseja remover?
          </Grid>
        </Grid>
      </Dialog.Content>
      <Dialog.Footer>
        <Button color='error' onClick={handleRemoverIntegracao}>
          Remover integração
        </Button>
        <Button variant='neutral' onClick={handleClose}>
          Cancelar
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
