import type { ContratarPlanoDialogProps } from './contratarPlanoDialog.types';

import { api } from 'api/api';
import useAuthStore from 'store/auth/auth';
import useAppStore from 'store/app/app';
import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash-es';
import type { ConfirmarDadosFormTypes } from 'shared/modalContratarSimulador/modalStepsContratacao/modalStepsContratacao.types';
import type { EditarMeusDadosBody } from 'api/empresa/empresa.types';

export function useContratarPlano({ handleClose }: Pick<ContratarPlanoDialogProps, 'handleClose'>) {
  const [etapa, setEtapa] = useState<number>(0);
  const [initialValues, setInitialValues] = useState<Partial<ConfirmarDadosFormTypes>>();

  const [buscarMeusDados, meusDados] = useAuthStore((s) => [s.buscarMeusDados, s.meusDados]);
  const [setNotification, setLoading, handleErrors] = useAppStore((s) => [
    s.setNotification,
    s.setLoading,
    s.handleErrors,
  ]);

  const _handleContratarPlano = async () => {
    setLoading(true);

    try {
      const response = await api.empresa.converterTesteGratis();

      if (response.status === 204) {
        setNotification({
          title: 'Assinatura confirmada com sucesso!',
        });
        buscarMeusDados();
      } else {
        throw new Error('Erro ao converter teste grátis');
      }
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const _handleAtualizarMeusDados = async (dados: EditarMeusDadosBody) => {
    try {
      setLoading(true);
      const response = await api.empresa.editarDados(dados);
      if (response.status === 204) {
        setNotification({
          title: 'Dados atualizados com sucesso',
        });
        return { error: false };
      }
    } catch (error) {
      handleErrors(error);
      return { error: true };
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitContratacao = useCallback(
    async (formValues: ConfirmarDadosFormTypes) => {
      switch (etapa) {
        case 0:
          if (!isEmpty(meusDados)) {
            const resp = await _handleAtualizarMeusDados({
              ...meusDados,
              nomeFantasia: formValues.nomeFantasia,
              cep: formValues.cep,
              rua: formValues.rua,
              numero: formValues.numero,
              bairro: formValues.bairro,
              complemento: formValues.complemento,
              codigoCidade: formValues.cidade.value,
            });
            if (resp && !resp.error) {
              setEtapa(1);
            }
          }
          break;
        case 1:
          await _handleContratarPlano();
          break;
      }
    },
    [etapa],
  );

  useEffect(() => {
    if (meusDados !== null) {
      setInitialValues({
        nomeFantasia: meusDados.nomeFantasia,
        bairro: meusDados.bairro,
        cidade: meusDados.codigoCidade
          ? {
              cidade: meusDados.cidade,
              value: meusDados.codigoCidade,
              key: meusDados.codigoCidade,
              label: `${meusDados.cidade} - ${meusDados.uf}`,
              uf: meusDados.uf,
            }
          : undefined,
        complemento: meusDados.complemento,
        numero: meusDados.numero,
        rua: meusDados.rua,
      });
    }
  }, []);

  return {
    etapa,
    initialValues,
    handleSubmitContratacao,
  };
}
