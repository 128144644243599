import { IntegradoComDominioDialog } from 'shared/integracaoDominio/integradoComDominioDialog/integradoComDominioDialog';
import { useIntegradoComDominioDialogStore } from 'shared/integracaoDominio/integradoComDominioDialog/useIntegradoComDominioDialog.store';
import { IntegrarComDominioDialog } from 'shared/integracaoDominio/integrarComDominioDialog/integrarComDominioDialog';
import { useIntegrarComDominioDialogStore } from 'shared/integracaoDominio/integrarComDominioDialog/integrarComDominioDialog.store';

export function Dialogs() {
  const _integrarComDomonioDialogOpen = useIntegrarComDominioDialogStore((s) => s.open);
  const _integradoComDominioDialogOpen = useIntegradoComDominioDialogStore((s) => s.open);

  return (
    <>
      {_integrarComDomonioDialogOpen && <IntegrarComDominioDialog />}
      {_integradoComDominioDialogOpen && <IntegradoComDominioDialog />}
    </>
  );
}
