import { Button, Dialog } from '@s_mart/core';
import { colorPalette } from '@s_mart/tokens';

import { Grid, Typography } from '@mui/material';

import ModalBoleto from './modalBoleto/modalBoleto';
import ModalPagamentoNaoIdentificado from './modalPagamentoNaoIdentificado/modalPagamentoNaoIdentificado';
import { ModalPendenciaFinanceiraDesktopContent } from './modalPendenciaFinanceiraDesktopContent';
import { ModalPendenciaFinanceiraMobileContent } from './modalPendenciaFinanceiraMobileContent';
import ModalPix from './modalPix/modalPix';
import useModalPendenciaFinanceira from './useModalPendenciaFinanceira';
import useCobrancasStore from 'store/cobrancas/useCobrancasStore';

const ModalPendenciaFinanceira = () => {
  const {
    handleClose,
    onPagarViaPix,
    mobileListLayout,
    empresaBloqueada,
    onPagarViaBoleto,
    cobrancasEmAberto,
    openModalPagamento,
    utilizouDesbloqueio,
    setOpenModalPagamento,
    onJaRealizeiPagamento,
    handlePagamentoRealizado,
    handlePagamentoNaoIdentificado,
  } = useModalPendenciaFinanceira();
  const modalPendenciasFinanceira = useCobrancasStore((state) => state.modalPendenciasFinanceira);

  if (!cobrancasEmAberto || cobrancasEmAberto === null) return;

  return (
    <>
      {modalPendenciasFinanceira && openModalPagamento?.tipo === 'pix' && (
        <ModalPix
          cobranca={openModalPagamento.cobranca}
          cobrancasEmAberto={cobrancasEmAberto}
          onClose={() => setOpenModalPagamento(null)}
          onFinalizar={handleClose}
          onPagamentoRealizado={handlePagamentoRealizado}
          onPagamentoNaoIdentificado={handlePagamentoNaoIdentificado}
        />
      )}

      {modalPendenciasFinanceira && openModalPagamento?.tipo === 'boleto' && (
        <ModalBoleto
          cobranca={openModalPagamento.cobranca}
          onJaRealizeiPagamento={onJaRealizeiPagamento}
          onClose={() => setOpenModalPagamento(null)}
          onFinalizar={handleClose}
        />
      )}

      {openModalPagamento?.tipo === 'pagamentoNaoIdentificado' && (
        <ModalPagamentoNaoIdentificado onClose={() => setOpenModalPagamento(null)} />
      )}
      <Dialog
        open={modalPendenciasFinanceira}
        disableEscapeKeyDown
        disableBackdropClick
        closeIcon
        onClose={handleClose}
        maxWidth='lg'
      >
        <Dialog.Header>
          {empresaBloqueada ? (
            <span>
              Sistema bloqueado por conta de
              <span style={{ color: colorPalette.red[500] }}>
                {cobrancasEmAberto?.length > 1 ? ' pendências financeiras' : ' pendência financeira'}
              </span>
            </span>
          ) : (
            'Seu boleto já está disponível'
          )}
        </Dialog.Header>

        <Dialog.Content>
          {mobileListLayout ? (
            <ModalPendenciaFinanceiraMobileContent
              cobrancasEmAberto={cobrancasEmAberto}
              empresaBloqueada={empresaBloqueada}
              onPagarViaBoleto={onPagarViaBoleto}
              onPagarViaPix={onPagarViaPix}
            />
          ) : (
            <ModalPendenciaFinanceiraDesktopContent
              cobrancasEmAberto={cobrancasEmAberto}
              empresaBloqueada={empresaBloqueada}
              onPagarViaBoleto={onPagarViaBoleto}
              onPagarViaPix={onPagarViaPix}
              utilizouDesbloqueio={utilizouDesbloqueio}
            />
          )}
        </Dialog.Content>

        <Dialog.Footer>
          {empresaBloqueada && (
            <Grid container display='flex' justifyContent='start'>
              {!utilizouDesbloqueio && (
                <Button variant='text' onClick={onJaRealizeiPagamento}>
                  <Typography variant='bodySM'>
                    {cobrancasEmAberto?.length > 1 ? 'Já realizei os pagamentos' : 'Já realizei o pagamento'}
                  </Typography>
                </Button>
              )}
            </Grid>
          )}
        </Dialog.Footer>
      </Dialog>
    </>
  );
};

export default ModalPendenciaFinanceira;
