import { create } from 'zustand';

import type { UsuariosState, UsuariosStore } from './usuarios.types';

import useAppStore from 'store/app/app';
import { api } from 'api/api';

const _initialState: UsuariosState = {
  usuarios: [],
  convites: [],
  loading: true,
};

export const useUsuariosStore = create<UsuariosStore>((set, get) => ({
  ..._initialState,
  buscarUsuarios: async () => {
    try {
      const { data: usuarios } = await api.usuarios.listar();
      const { data: convites } = await api.convites.listar();

      set({
        usuarios: usuarios.data,
        convites: convites.data,
        loading: false,
      });
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    }
  },
  removerConviteUsuario: async (id) => {
    try {
      useAppStore.getState().setLoading(true);

      await api.convites.excluir(Number(id));

      useAppStore.getState().setNotification({
        title: 'Convite removido',
      });

      set((state) => ({
        convites: state.convites.filter((convite) => convite.codigo !== id),
      }));

      useAppStore.getState().setLoading(false);
    } catch (error) {
      useAppStore.getState().handleErrors(error);
    }
  },
  limparStore() {
    set(_initialState);
  },
}));
