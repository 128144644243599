import { CircularProgress, Typography } from "@mui/material";
import * as React from 'react';
import * as styled from './modalLoader.styles';

type _ModalLoaderProps = React.PropsWithChildren<{
  message?: string;
}>;

const ModalLoader = ({ message }: _ModalLoaderProps) => {
  return (
    <styled.Box>
      <CircularProgress size={48} thickness={4} />
      {message && <Typography variant='titleSM'>{message}</Typography>}
    </styled.Box>
  );
};

export default ModalLoader;
