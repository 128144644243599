import { ZodApiResponseParser } from 'api/zodApi';
import { z } from 'zod';

export enum SituacaoCobranca {
  EMITIDO = 'EMITIDO',
  LIQUIDADO = 'LIQUIDADO',
  BAIXADO = 'BAIXADO',
  ISENTADO = 'ISENTADO',
}

enum FormaPagamentoCobranca {
  BOLETO = 1,
  CARTAO_CREDITO = 3,
}

enum SituacaoNFSeCobranca {
  INDISPONIVEL,
  DISPONIVEL,
}
const schemaItemListarCobrancasResponse = z.object({
  codigo: z.number(),
  dataBaixa: z.string().nullable(),
  dataDesbloqueio: z.string().nullable(),
  dataEmissao: z.string(),
  dataPagamento: z.string().nullable(),
  dataVencimento: z.string(),
  descricao: z.string().nullable(),
  formaPagamento: z.nativeEnum(FormaPagamentoCobranca),
  identificador: z.string(),
  linhaDigitavel: z.string(),
  nfseEmitida: z.nativeEnum(SituacaoNFSeCobranca),
  observacaoInterna: z.string().nullable(),
  pixQrCode: z.string().nullable(),
  situacao: z.nativeEnum(SituacaoCobranca),
  valor: z.number(),
});

const schemaListarCobrancasResponse = z.object({
  data: schemaItemListarCobrancasResponse.array(),
});

export const parseListarCobrancasResponse = ZodApiResponseParser.createParser(schemaListarCobrancasResponse);

export type ListarCobrancasResponse = z.infer<typeof schemaListarCobrancasResponse>;
export type ItemListarCobrancasResponse = z.infer<typeof schemaItemListarCobrancasResponse>;
