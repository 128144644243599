import { axiosInstance, type DataResponse } from 'api/axiosConfig';
import type { BuscarMeusDadosResponse, EditarMeusDadosBody, Empresa, RecursosResponse } from './empresa.types';
import { parseListarCobrancasResponse } from './schemas/listarCobrancas';
import { parseBaixarBoletoResponse } from './schemas/baixarBoleto';
import { parseConsultarCobrancaResponse } from './schemas/consultarCobranca';

export const empresa = {
  buscarDados: () => axiosInstance.get<DataResponse<BuscarMeusDadosResponse>>('/parceiro/meus-dados'),

  buscarRecursos: () => axiosInstance.get<DataResponse<RecursosResponse>>(`/parceiro/meus-dados/meu-plano/recursos`),

  buscarCobrancas: () => axiosInstance.get(`/parceiro/meus-dados/cobrancas`).then(parseListarCobrancasResponse),

  consultarCobranca: (id: number) =>
    axiosInstance.get(`/parceiro/meus-dados/cobrancas/${id}/consultar`).then(parseConsultarCobrancaResponse),

  baixarBoleto: (boletoIdentificador: string) =>
    axiosInstance
      .get(`/parceiro/meus-dados/cobrancas/baixar-boleto/${boletoIdentificador}`)
      .then(parseBaixarBoletoResponse),

  listarEmpresasUsuario: () =>
    axiosInstance.get<DataResponse<Record<string, Empresa>>>('/parceiro/company/users-companies'),

  trocarEmpresa: (emp: number) => axiosInstance.get(`/parceiro/company/change/${emp}`),

  solicitarDesbloqueio: () => axiosInstance.post(`/parceiro/meus-dados/solicitar-desbloqueio`),

  gerarFaturaPrepago: () => axiosInstance.post(`/parceiro/meus-dados/gerar-fatura-prepago`, undefined),

  editarDados: (body: EditarMeusDadosBody) => axiosInstance.patch('/parceiro/meus-dados', body),

  converterTesteGratis: () => axiosInstance.patch(`/parceiro/meus-dados/converter-teste-gratis`, undefined),

  salvarFreshChatID: (id: string) => axiosInstance.patch(`/storefreshchatid/${id}`),
};
