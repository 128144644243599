import { Config } from 'config';
import { useCallback } from 'react';

export function useConteudoPrimeiroPasso() {
  const textoSistema =
    Config.systemData.name === 'SimplesCTe' ? 'todos os CTes serão enviados' : 'todas as NFes serão enviadas';

  const handleOpenWhatsApp = useCallback(() => {
    window.open(
      `https://api.whatsapp.com/send?phone=${Config.systemData.whatsApp}&text=Olá, preciso de ajuda para integrar com a Domínio.`,
      '_blank',
    );
  }, []);

  return {
    textoSistema,
    handleOpenWhatsApp,
  };
}
