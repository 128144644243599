import { CircularProgress, Grid } from "@mui/material";
import { Field } from '@l_ine/core';
import { cep, composeValidators, required } from '@l_ine/rules';
import useCep from 'hooks/useCep/useCep';
import useCities from 'hooks/useCities';
import { isEmpty } from 'lodash-es';
import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import type { ConfirmarDadosFormTypes } from '../modalStepsContratacao.types';

const StepDadosCadastrais = () => {
  const { handleBuscarCep, localidade, cepLoading } = useCep();
  const { cities, handleSearchCities, citiesLoading } = useCities();
  const { change, batch } = useForm<ConfirmarDadosFormTypes>();

  useEffect(() => {
    if (!isEmpty(localidade)) {
      batch(() => {
        change('cidade', {
          cidade: localidade.cidade,
          key: localidade.codigo_ibge,
          value: localidade.codigo_ibge,
          label: `${localidade.cidade} - ${localidade.uf}`,
          uf: localidade.uf,
        });
        if (localidade.bairro) change('bairro', localidade.bairro);
        if (localidade.complemento) change('complemento', localidade.complemento);
        if (localidade.logradouro) change('rua', localidade.logradouro);
      });
    }
  }, [localidade]);

  return (
    (<Grid container spacing={2}>
      <Grid item xs={12} md={9}>
        <Field name='nomeFantasia' label='Nome fantasia' required validate={required} />
      </Grid>
      <Grid item xs={12} md={3}>
        <Field
          name='cep'
          label='CEP'
          mask='cep'
          required
          validate={composeValidators(required, cep)}
          disabled={cepLoading}
          endAdornment={cepLoading && <CircularProgress size={20} />}
        />
        <OnChange name='cep'>
          {(value: string, previous: string) => {
            if (value !== previous && /^[0-9]{8}$/.test(value) && previous !== '') {
              handleBuscarCep(value);
            }
          }}
        </OnChange>
      </Grid>
      <Grid item xs={12} md={6}>
        <Field name='rua' label='Rua' required validate={required} />
      </Grid>
      <Grid item xs={4} md={2}>
        <Field name='numero' label='Número' required validate={required} />
      </Grid>
      <Grid item xs={8} md={4}>
        <Field name='bairro' label='Bairro' required validate={required} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field name='complemento' label='Complemento' />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          name='cidade'
          label='Cidade'
          required
          validate={required}
          mode='searchable'
          options={cities}
          isLoading={citiesLoading}
          onInputChange={handleSearchCities}
        />
      </Grid>
    </Grid>)
  );
};

export default StepDadosCadastrais;
