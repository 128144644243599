import { create } from 'zustand';

import type { Cliente } from 'store/clientes/clientes.types';
import { type IntegrarComDominioDialogStore, Passos } from './integrarComDominioDialog.types';

export const useIntegrarComDominioDialogStore = create<IntegrarComDominioDialogStore>((set) => ({
  open: false,
  dadosCliente: null,
  dadosIntegracao: null,
  passo: Passos.INTEGRAR_COM_DOMINIO,

  setDadosIntegracao: (dadosIntegracao) => set({ dadosIntegracao }),
  handleOpen: (dadosCliente) => {
    set({ open: true, dadosCliente });
  },
  handleClose: () => {
    set({ open: false, dadosCliente: null, passo: Passos.INTEGRAR_COM_DOMINIO });
  },

  handleProximoPasso: () => set((state) => ({ passo: state.passo + 1 })),
  handleVoltarPasso: () => set((state) => ({ passo: state.passo - 1 })),

  openTutorialCadastroChave: false,
  handleOpenTutorialCadastroChave: () => set({ openTutorialCadastroChave: true }),
  handleCloseTutorialCadastroChave: () => set({ openTutorialCadastroChave: false }),
}));
