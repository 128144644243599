import { alpha, styled } from '@mui/material';
import { Button } from '@s_mart/core';
import { colorPalette } from '@s_mart/tokens';

type ButtonPendenciaProps = {
  // Utiliza prop 'data-estilo-botao' pois evita erro de runtime no console de erro de prop não existente para o elemento html
  'data-estilo-botao': number;
};

export const ButtonPendencia = styled(Button)<ButtonPendenciaProps>(({ theme, 'data-estilo-botao': estilo }) => ({
  ...(estilo === 0 && {
    color: colorPalette.neutral[900],
    borderColor: theme.palette.neutral.main,
    '&:hover': {
      borderColor: theme.palette.neutral.main,
      backgroundColor: alpha(theme.palette.neutral.main, 0.2),
    },
  }),
  ...(estilo === 1 && {
    backgroundColor: colorPalette.yellow[75],
    borderColor: colorPalette.yellow[300],
    color: colorPalette.neutral[900],
    '&:hover': {
      borderColor: colorPalette.yellow[300],
      backgroundColor: colorPalette.yellow[100],
    },
  }),
  ...(estilo === 2 && {
    color: theme.palette.text.primary,
    backgroundColor: alpha(theme.palette.error.main, 0.5),
    borderColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.8),
      borderColor: theme.palette.error.main,
    },
  }),
}));
