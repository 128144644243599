import { Backdrop } from "@mui/material";
import { IconButton, LIcon } from '@s_mart/core';
import React from 'react';
import { ImageOverlayToolbar, ImgOverlayContainer } from './imagePreview.styles';
import { lineTimes } from '@s_mart/light-icons';

type ImageOverlayProps = {
  imgProps: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
  onClose(): void;
};

export const ImageOverlay = ({ imgProps, onClose }: ImageOverlayProps) => {
  return (
    <Backdrop open sx={{ zIndex: 105, background: 'rgba(0,0,0,0.75)' }} onClick={onClose}>
      <ImageOverlayToolbar>
        <IconButton variant='text' onClick={onClose}>
          <LIcon icon={lineTimes} color='white' size='48px' />
        </IconButton>
      </ImageOverlayToolbar>
      <ImgOverlayContainer>
        <img {...imgProps} onClick={(e) => e.stopPropagation()} />
      </ImgOverlayContainer>
    </Backdrop>
  );
};
