import { useCallback, useState } from 'react';

import { useMediaQuery } from '@mui/material';

import type { ModaisPagmentoState } from './modalPendenciaFinanceira.types';

import { api } from 'api/api';
import useAppStore from 'store/app/app';
import useAuthStore from 'store/auth/auth';
import { useClientesStore } from 'store/clientes/clientes';
import type { CobrancaEmAberto } from 'store/cobrancas/cobrancas.types';
import useCobrancasStore from 'store/cobrancas/useCobrancasStore';

const useModalPendenciaFinanceira = () => {
  const empresaBloqueada = useAuthStore((state) => state.bloqueado);
  const cobrancasEmAberto = useCobrancasStore((state) => state.cobrancasEmAberto);
  const listarCobrancas = useCobrancasStore((state) => state.listarCobrancas);
  const setNotification = useAppStore((state) => state.setNotification);
  const handleErrors = useAppStore((state) => state.handleErrors);
  const setLoading = useAppStore((state) => state.setLoading);
  const [buscarMeusDados, buscarConfiguracoesUsuario] = useAuthStore((state) => [
    state.buscarMeusDados,
    state.buscarConfiguracoesUsuario,
  ]);

  const buscarClientes = useClientesStore((state) => state.buscarClientes);

  const [openModalPagamento, setOpenModalPagamento] = useState<ModaisPagmentoState | null>(null);

  const utilizouDesbloqueio = cobrancasEmAberto?.[0]?.dataDesbloqueio;
  const mobileListLayout = useMediaQuery('(max-width: 600px)');
  const configuracoesUsuario = useAuthStore((s) => s.configuracoesUsuario);
  const setModalPendenciasFinanceira = useCobrancasStore((s) => s.setModalPendenciasFinanceiras);

  const handleClose = useCallback(async () => {
    // biome-ignore lint/style/noNonNullAssertion: Deve existir `cobrancasEmAberto` para poder abrir a modal e depois fechar ela
    const cobrancasVisualizadas = cobrancasEmAberto!.reduce<Record<string, string>>((acc, curr) => {
      acc[curr.identificador] = new Date().toJSON();

      return acc;
    }, {});

    const boletosJaVisualizadosAnteriormente = Object.keys(configuracoesUsuario?.boletosVisualizados ?? {});

    const novosBoletosVisualizados = Object.keys(cobrancasVisualizadas);

    // Se houver algum boleto que ainda não havia sido visualizado, atualiza a lista de boletos já visualizados
    if (
      !boletosJaVisualizadosAnteriormente.length ||
      !novosBoletosVisualizados.every((key) => boletosJaVisualizadosAnteriormente.includes(key))
    ) {
      await api.usuarios.atualizarConfiguracoes({
        configuracao: {
          ...configuracoesUsuario,
          boletosVisualizados: {
            ...configuracoesUsuario?.boletosVisualizados,
            ...cobrancasVisualizadas,
          },
        },
      });
      buscarConfiguracoesUsuario();
    }
    setModalPendenciasFinanceira(false);
  }, [buscarConfiguracoesUsuario, setModalPendenciasFinanceira, configuracoesUsuario, cobrancasEmAberto?.reduce]);

  const onPagarViaPix = (cobranca: CobrancaEmAberto) => {
    setOpenModalPagamento({ tipo: 'pix', cobranca });
  };

  const onPagarViaBoleto = (cobranca: CobrancaEmAberto) => {
    setOpenModalPagamento({ tipo: 'boleto', cobranca });
  };
  const fetchEmpresa = () => {
    return Promise.all([buscarMeusDados(), buscarConfiguracoesUsuario(), buscarClientes()]);
  };
  const onJaRealizeiPagamento = async () => {
    try {
      setLoading(true);

      await api.empresa.solicitarDesbloqueio();

      await fetchEmpresa();

      setNotification({
        title: 'Sistema desbloqueado',
        variant: 'success',
      });
      setLoading(false);

      handleClose();
    } catch (error: any) {
      if (cobrancasEmAberto) handlePagamentoNaoIdentificado(cobrancasEmAberto[0]);

      handleErrors(error);
    }
  };

  const handlePagamentoNaoIdentificado = (cobranca: CobrancaEmAberto) => {
    setOpenModalPagamento({ tipo: 'pagamentoNaoIdentificado', cobranca });
  };

  const handlePagamentoRealizado = async () => {
    await fetchEmpresa();
    await listarCobrancas();

    if (cobrancasEmAberto && cobrancasEmAberto.length > 1) {
      setNotification({
        title: 'Cobrança liquidada',
        variant: 'success',
      });

      setOpenModalPagamento(null);
    } else {
      setNotification({
        title: 'Sistema desbloqueado',
        variant: 'success',
      });

      handleClose();
    }
  };

  return {
    handleClose,
    onPagarViaPix,
    mobileListLayout,
    empresaBloqueada,
    onPagarViaBoleto,
    cobrancasEmAberto,
    openModalPagamento,
    utilizouDesbloqueio,
    setOpenModalPagamento,
    onJaRealizeiPagamento,
    handlePagamentoRealizado,
    handlePagamentoNaoIdentificado,
  };
};

export default useModalPendenciaFinanceira;
