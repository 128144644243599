import { Typography } from "@mui/material";
import { Button } from '@s_mart/core';

import type { ErrorProps } from './error.types';
import * as styled from './error.styles';

const ErrorLayout: React.FC<ErrorProps> = ({ statusCode }) => {
  const errors = {
    500: {
      message: 'Nosso servidor não conseguiu retornar sua solicitação.',
      explanation: 'Ocorreu um erro em um dos nossos servidores, tente novamente mais tarde.',
      image: '/static/images/500.png',
    },
    404: {
      message: 'Não conseguimos encontrar essa página.',
      explanation: 'A página que você está procurando pode ter sido movida, removida, renomeada ou nunca ter existido.',
      image: '/static/images/404.png',
    },
  };

  return (
    <>
      <styled.ErrorGrid>
        <div className='error-message'>
          <Typography variant='titleXL'>{statusCode}</Typography>
          <Typography variant='bodyXL' style={{ marginTop: 16 }}>
            {errors[statusCode].message}
          </Typography>
          <Typography variant='bodySM' style={{ margin: '16px 0px' }}>
            {errors[statusCode].explanation}
          </Typography>
          <Button
            data-testid='s'
            onClick={() => {
              window.location.href = '/';
              if (statusCode === 500) window.location.reload();
            }}
          >
            Voltar para a tela inicial
          </Button>
        </div>
        <div className='error-image'>
          <img src={errors[statusCode].image} />
        </div>
      </styled.ErrorGrid>
    </>
  );
};

export default ErrorLayout;
