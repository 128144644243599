import { Config } from 'config';
import { EnumSistemas } from 'constants/sistemas';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import type { FC } from 'react';
import { Navigate, useLocation } from 'react-router';
import useAuthStore from 'store/auth/auth';
import { ROTAS_PRIVADAS } from './permissoesRotasPrivadas';

export const privateRouteMiddleware = (Component: FC) => {
  return function PrivateComponent() {
    const [authLoading, recursosDisponiveis] = useAuthStore((state) => [state.authLoading, state.recursosDisponiveis]);

    const { pathname } = useLocation();

    const possuiSomentePlanoSimulador =
      Config.systemData.code === EnumSistemas.AGRONOTA &&
      recursosDisponiveis !== null &&
      Object.values(recursosDisponiveis).length === 1 &&
      recursosDisponiveis['simulador-ir-parceiro'];

    const _token = Cookies.get('id_token');

    if ((!_token && !authLoading) || (_token && !jwtDecode(_token))) {
      return <Navigate to='/entrar' />;
    }

    if (pathname === ROTAS_PRIVADAS.RESUMO && Config.systemData.code === EnumSistemas.SIMPLESCTE) {
      return <Navigate to={ROTAS_PRIVADAS.CLIENTES} />;
    }

    if (pathname === ROTAS_PRIVADAS.CLIENTES && Config.systemData.code === EnumSistemas.AGRONOTA) {
      return <Navigate to={ROTAS_PRIVADAS.RESUMO} />;
    }

    if (pathname === ROTAS_PRIVADAS.RESUMO && possuiSomentePlanoSimulador) {
      return <Navigate to={ROTAS_PRIVADAS.SIMULADOR_IR} />;
    }

    return <Component />;
  };
};
